'use client';

import React, { createContext, useContext, useRef, useState } from 'react';
import { useCarouselScrollHandlers } from '../hooks/useCarouselScrollHandlers';
import { UseReelResults, useReel } from '@volvo-cars/react-headless';
import { useCarouselFilters } from '../hooks/useCarouselFilters/useCarouselFilters';
import { useFullWidthCarousel } from '../hooks/useFullWidthCarousel';
import useTouchScrollEvent from '../hooks/useTouchScrollEvent/useTouchScrollEvent';
import { CarouselBleed } from '../../cars/src/types/carouselTypes';

type CarouselContextType = {
  ref: React.RefObject<HTMLDivElement>;
} & UseReelResults &
  ReturnType<typeof useCarouselScrollHandlers> &
  ReturnType<typeof useCarouselFilters> & {
    carouselIsScrollable: boolean;
    bleed?: CarouselBleed;
    totalAmountOfCards: number;
    setTotalAmountOfCards: React.Dispatch<React.SetStateAction<number>>;
  };

const CarouselContext = createContext<CarouselContextType | null>(null);

export const useCarousel = () => {
  const context = useContext(CarouselContext);
  if (!context) {
    throw new Error('useCarousel must be used within a CarouselProvider');
  }
  return context;
};

export const CarouselProvider = ({
  children,
  bleed,
}: React.PropsWithChildren<{
  bleed?: CarouselBleed;
}>) => {
  const ref = useRef<HTMLDivElement>(null);
  const reelProps = useReel({ ref });
  const [totalAmountOfCards, setTotalAmountOfCards] = useState(0);

  useFullWidthCarousel(ref, Boolean(bleed));
  useTouchScrollEvent(ref);

  const scrollHandlersProps = useCarouselScrollHandlers(ref);
  const filterProps = useCarouselFilters();
  const carouselIsScrollable =
    (ref.current?.scrollWidth ?? 0) > (ref.current?.clientWidth ?? 0);
  return (
    <CarouselContext.Provider
      value={{
        ref,
        ...scrollHandlersProps,
        ...filterProps,
        ...reelProps,
        carouselIsScrollable,
        bleed,
        totalAmountOfCards,
        setTotalAmountOfCards,
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};
