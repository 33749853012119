'use client';
import React, { ReactNode, createContext } from 'react';
import { FeaturedDocument } from '../types';
import { useFeaturedDocuments } from './hooks/useFeaturedDocuments';

type FeaturedDocumentsContextType = {
  documents: FeaturedDocument[] | null;
  isLoading: boolean;
  title?: string;
  description?: string;
};

/**
 * The context for the featured documents
 */
export const FeaturedDocumentsContext =
  createContext<FeaturedDocumentsContextType>({
    documents: [],
    isLoading: false,
    title: '',
    description: '',
  });

interface FeaturedDocumentsProviderProps {
  children: ReactNode;
  limit: number;
  featureListName: string;
}

/**
 * @description The provider for the featured documents - fetches the featured documents and provides them to the children. Renders a skeleton while loading.
 * @param children - The children to render
 * @param limit - The number of documents to return
 * @param featureListName - The name of the feature list
 * @returns
 */
export const FeaturedDocumentsProvider = ({
  children,
  limit,
  featureListName,
}: FeaturedDocumentsProviderProps) => {
  const { featuredList, isLoading } = useFeaturedDocuments({
    featureListName,
  });

  const featuredDocuments = featuredList?.featuredDocuments
    ? [...featuredList.featuredDocuments.slice(0, limit)]
    : null;

  return (
    <FeaturedDocumentsContext.Provider
      value={{
        documents: featuredDocuments,
        isLoading,
        title: featuredList?.title,
        description: featuredList?.description,
      }}
    >
      {children}
    </FeaturedDocumentsContext.Provider>
  );
};
