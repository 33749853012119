import {
  type FlagsProviderProps,
  useFeatureFlagsWithoutProvider,
} from '@vcc-www/feature-flags';
import { useCurrentMarketSite } from '@vcc-www/market-sites';

export const useCarCarouselFeatureFlags = () => {
  const { siteSlug } = useCurrentMarketSite();
  const { globalFlags } = useFeatureFlagsWithoutProvider({
    clientName: 'carousel',
    globalFlagsEnv: getValidFlagEnv(),
    customAttributes: { siteSlug: siteSlug || '' },
  });

  const shouldUseCarPriceComponent = Boolean(globalFlags['showCarPrice']);

  return { shouldUseCarPriceComponent };
};

const getValidFlagEnv = () => {
  const globalFlagsEnv =
    process.env.DEPLOY_ENV || process.env.NEXT_PUBLIC_DEPLOY_ENV || 'prod';
  if (isValidFlagEnv(globalFlagsEnv)) return globalFlagsEnv;
  return 'prod';
};

const isValidFlagEnv = (
  str?: string,
): str is FlagsProviderProps['globalFlagsEnv'] => {
  if (
    str === 'dev' ||
    str === 'development' ||
    str === 'prod' ||
    str === 'production' ||
    str === 'qa' ||
    str === 'test'
  )
    return true;
  return false;
};
