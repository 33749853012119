import React from 'react';
import { Disclaimer as DisclaimerBase } from '@vcc-package/text';
import { maxContentWidth10Columns } from '@vcc-www/constants/layout';
import { DisclaimerParams, DisclaimerProps } from './ImageWithText.types';

const Disclaimer = ({
  disclaimer,
  alignDisclaimer,
  isBlue,
  isMobile,
  ...props
}: DisclaimerProps &
  DisclaimerParams & {
    isBlue: boolean;
    isMobile: boolean;
    className?: string;
  }) => {
  if (!disclaimer) return null;

  const textAlign = isMobile
    ? 'center'
    : alignDisclaimer === 'right'
      ? 'right'
      : 'left';

  return (
    <div
      data-autoid={`disclaimer:${isMobile ? 'mobile' : 'desktop'}`}
      {...props}
    >
      <DisclaimerBase
        extend={{
          textAlign,
          margin: '32px auto 0',
          maxWidth: maxContentWidth10Columns,
          alignSelf: 'flex-start',
        }}
        textExtend={isBlue ? { color: 'primitive.white' } : {}}
        text={disclaimer}
      />
    </div>
  );
};

export default Disclaimer;
