import { cssMerge } from '@volvo-cars/css/utils';
import React from 'react';

const validHeadings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
type HeadingLevels = (typeof validHeadings)[number];

const CarouselHeading = ({
  headingLevel = 'h2',
  children,
  className,
  ...props
}: React.PropsWithChildren<
  { headingLevel: HeadingLevels } & React.HTMLAttributes<HTMLHeadingElement>
>) => {
  headingLevel = isValidHeading(headingLevel) ? headingLevel : 'h2';
  return React.createElement(
    headingLevel,
    {
      className: cssMerge('heading-3 font-medium', className),
      ...props,
    },
    children,
  );
};

export default CarouselHeading;

const isValidHeading = (heading: unknown): heading is HeadingLevels => {
  if (typeof heading !== 'string') return false;
  if (!validHeadings.includes(heading)) return false;
  return true;
};
