import { supportContentRequest } from '../api/supportContentRequest';
import { QUERY_FEATURED_DOCUMENTS_LIST } from '../graphql/FeaturedDocumentsList';
import { FeaturedDocument } from '../types';

interface FetchFeaturedDocumentsListProps {
  siteSlug: string;
  language: string;
  featureListName: string;
}

/**
 * @description Fetches the featured documents using the feature list name, site slug and language.
 * @param siteSlug - The site slug for the market, e.g. 'uk'
 * @param language - The language for the market, e.g. 'en-gb'
 * @param featureListName - The name of the feature list
 * @returns - The featured documents
 */
export const fetchFeaturedDocumentsList = async ({
  siteSlug,
  language,
  featureListName,
}: FetchFeaturedDocumentsListProps) => {
  const data = await supportContentRequest(QUERY_FEATURED_DOCUMENTS_LIST, {
    marketSlug: siteSlug,
    language: [language],
    documentId: featureListName,
  });
  const list = data?.market?.contextualKnowledge?.document;
  if (!list || !list.children.length) {
    return null;
  }

  const documents = list.children || null;
  const featuredDocuments: FeaturedDocument[] = documents?.map(
    (document: any) => {
      return {
        documentId: document.documentId,
        title: document.stringContent.title,
        description: document.stringContent.description,
      };
    },
  );

  return {
    description: list.stringContent?.description,
    title: list.stringContent?.title,
    featuredDocuments: featuredDocuments,
  };
};
