'use client';
import React, { createContext, useContext, useState } from 'react';
import { FeatureFlags } from './constants';

const LaunchDarklyContext = createContext<{
  launchDarklyFlags: FeatureFlags;
  setLaunchDarklyFlags: React.Dispatch<React.SetStateAction<FeatureFlags>>;
}>({ launchDarklyFlags: {}, setLaunchDarklyFlags: () => {} });

export const FeatureFlagsProvider: React.FC<
  React.PropsWithChildren<{ flags: FeatureFlags }>
> = ({ children, flags }) => {
  const [launchDarklyFlags, setLaunchDarklyFlags] = useState(flags);

  return (
    <LaunchDarklyContext.Provider
      value={{ launchDarklyFlags, setLaunchDarklyFlags }}
    >
      {children}
    </LaunchDarklyContext.Provider>
  );
};

export function useFeatureFlags() {
  return useContext(LaunchDarklyContext);
}

export default LaunchDarklyContext;
