'use client';

import React, { useRef } from 'react';
import { useCarousel } from '../providers/CarouselProvider';
import { cssMerge } from '@volvo-cars/css/utils';
import { useTracker } from '@volvo-cars/tracking';
import styles from '../Carousel.module.css';

const CarouselIndicator = ({
  className,
}: React.HTMLAttributes<HTMLButtonElement>) => {
  const indicatorWrapper = useRef<HTMLDivElement>(null);
  const { scrollPercentage, ref, carouselIsScrollable } = useCarousel();

  const indicatorWrapperWidth = indicatorWrapper.current?.clientWidth || 0;

  const wrapperWidth = getCarouselWidth(ref.current);
  // Calculate the visible children dynamically
  const visibleChildrenCount =
    wrapperWidth / (ref.current?.children[0]?.clientWidth ?? 0);

  const indicatorCount =
    (ref.current?.children.length ?? 0) / visibleChildrenCount;
  const indicatorWidth = indicatorWrapperWidth / indicatorCount;

  const transformPx =
    (scrollPercentage / 100) * (indicatorWrapperWidth - indicatorWidth);

  const tracker = useTracker();

  const handleIndicatorClick = (event: React.MouseEvent) => {
    const carouselElement = ref.current;
    const indicatorWrapperElement = indicatorWrapper.current;
    const indicatorElement = indicatorWrapperElement?.firstElementChild;

    if (
      !carouselElement ||
      !indicatorWrapperElement ||
      !(indicatorElement instanceof HTMLElement)
    ) {
      return;
    }

    const totalScrollWidth =
      carouselElement.scrollWidth - carouselElement.clientWidth;

    const indicatorWidth = indicatorWrapperElement.offsetWidth;

    const clickX =
      event.clientX - indicatorWrapperElement.getBoundingClientRect().left;
    const clickPercentage = clickX / indicatorWidth;
    const carouselPosition = clickPercentage * totalScrollWidth;

    const currentScrollPosition = carouselElement.scrollLeft;
    const direction =
      carouselPosition > currentScrollPosition ? 'forward' : 'backward';

    carouselElement.scrollTo({
      left: carouselPosition,
      behavior: 'smooth',
    });

    tracker.interaction({
      eventAction: 'indicator|click',
      eventLabel: direction,
    });
  };

  return (
    <button
      className={cssMerge(
        'block',
        'w-full',
        styles.indicator,
        className,
        !carouselIsScrollable && 'hidden',
      )}
      onClick={handleIndicatorClick}
      type="button"
      aria-label="carousel-slider"
    >
      <div
        style={{
          height: 2,
          position: 'relative',
          pointerEvents: !carouselIsScrollable ? 'none' : 'auto',
          visibility: carouselIsScrollable ? 'visible' : 'hidden',
        }}
        className="flex-row w-full rounded bg-secondary overflow-hidden"
        ref={indicatorWrapper}
      >
        <div
          style={{
            width: `${indicatorWidth}px`,
            transform: `translate3d(${transformPx}px,0,0)`,
          }}
          className="rounded h-full bg-always-black"
        />
      </div>
    </button>
  );
};

export default CarouselIndicator;

const getCarouselWidth = (carousel: HTMLDivElement | null): number => {
  if (!carousel) return 0;
  const computedStyle = window.getComputedStyle(carousel);
  const width = carousel.clientWidth;
  const paddingLeft = parseFloat(computedStyle.paddingLeft);
  const paddingRight = parseFloat(computedStyle.paddingRight);
  return width - paddingLeft - paddingRight;
};
