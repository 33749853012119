import React from 'react';
import { CarouselCarData, CarouselItemProps } from '../../types/carouselTypes';
import Price from './CarouselItemPrice';
import { cssMerge, cssJoin } from '@volvo-cars/css/utils';
import styles from '../../Carousel.module.css';
import { ResponsiveImage } from '@vcc-package/media';
import { getModelSlugFromKey } from '../../utils/getModelSlugFromKey';
import { CarPriceProviderWithSiteSlug } from '../../../providers/CarPriceProviderWithSiteSlug';
import CarInfo from './CarInfo';

const CarouselItem = ({
  car,
  purchaseFromText,
  carPriceProps,
  badge,
  altText,
  className,
  hideIfNoPrice,
}: CarouselItemProps & {
  car: CarouselCarData;
  className?: string;
}) => {
  const modelSlug = getModelSlugFromKey(car.key);
  const altTextWithModel = altText?.replace(
    '{model}',
    `${car.displayName} ${car.engineType}`,
  );
  const sanitizedModelTitle = sanitizeModelTitle(car.displayName, modelSlug);

  return (
    <CarPriceProviderWithSiteSlug>
      <div
        className={cssMerge(
          'p-24 gap-24 flex flex-col justify-between',
          styles.card_wrapper,
          className,
        )}
      >
        <small
          className={cssJoin(
            'micro font-medium',
            badge?.color ?? 'text-feedback-green',
            styles.badge,
          )}
        >
          {badge?.text}
        </small>
        <ResponsiveImage
          alt={altTextWithModel}
          images={{
            sm: { alt: altTextWithModel, src: car.image },
          }}
          className="object-contain aspect-21/9 w-xs mx-auto"
          style={{ maxWidth: '240px' }}
        />
        <hgroup className="stack-text">
          <h2 className="font-20 font-medium" data-autoid="model-name">
            {sanitizedModelTitle}
          </h2>
          <p
            className="font-16 text-secondary"
            data-autoid="car-engine-type-and-body"
          >
            <CarInfo engineType={car.engineType} body={car.body} />
          </p>
          <p className="mt-8">
            <Price
              hideIfNoPrice={hideIfNoPrice}
              purchaseFromText={purchaseFromText}
              cashPrice={car.cashPrice}
              modelSlug={modelSlug}
              carPriceProps={carPriceProps}
            />
          </p>
        </hgroup>
      </div>
    </CarPriceProviderWithSiteSlug>
  );
};

export default CarouselItem;

// TODO: Once fed graph has updated the MY25 model names correctly in `modelFamily`, we can remove this function and migrate back to use `modelFamily.displayName` instead of `model.displayName`
function sanitizeModelTitle(
  displayName: string,
  modelSlug: string,
): string | undefined {
  if (modelSlug.includes('-cross-country')) {
    return displayName;
  }
  return displayName.split(' ').shift();
}
