'use client';
// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { ExtendCSS, Block, Text, Inline } from 'vcc-ui';
import { AssetViewer } from './AssetViewer';
import { AssetSpecificationExampleType } from './types';

type Props = AssetSpecificationExampleType & {
  resolution: [number, number];
};

export const AssetExample: React.FC<React.PropsWithChildren<Props>> = ({
  Component,
  componentSettings = {},
  maxWidth = null,
  resolution,
  pattern = 'default',
}) => {
  const MAX_HEIGHT = 500;
  const ratio = resolution[1] / resolution[0];
  const forcedMaxWidth = Math.floor(
    MAX_HEIGHT * (resolution[0] / resolution[1]),
  );

  const backgroundPatterns: any = {
    none: 'transparent',
    default: `repeating-linear-gradient(
        -45deg,
        #f5f5f5,
        #f5f5f5 10px,
        #f0f0f0 10px,
        #f0f0f0 20px
      )`,

    transparent:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGklEQVQYlWP4+vXrf3TMgA0MBYWDzDkUKQQA3l7BXXONiCkAAAAASUVORK5CYII=) repeat',
  };

  const finalMaxWidth =
    typeof maxWidth === 'number'
      ? Math.min(maxWidth, forcedMaxWidth)
      : forcedMaxWidth;

  const INITIAL_SETTINGS = Object.keys(componentSettings).reduce(
    (prev, cur) => ({
      ...prev,
      [cur]: componentSettings[cur].values[componentSettings[cur].default],
    }),
    {},
  );

  const [exampleSettings, setExampleSettings] =
    useState<AssetSpecificationExampleType['componentSettings']>(
      INITIAL_SETTINGS,
    );

  return (
    <div>
      <Block
        extend={wrapperCSS({
          maxWidth: finalMaxWidth,
          background: backgroundPatterns[pattern] || backgroundPatterns.default,
        })}
      >
        <Ruler direction="horizontal">{resolution[0]}</Ruler>
        <Ruler direction="vertical">{resolution[1]}</Ruler>
        <Block extend={boxSpacerCSS({ ratio })}>
          <AssetViewer />
          <Block extend={innerWrapperCSS}>
            {Component && <Component {...exampleSettings} />}
          </Block>
        </Block>
      </Block>
      <div>
        {Object.keys(componentSettings).map((setting: string) => (
          <Text key={setting} variant="bates" extend={exampleSettingsCSS}>
            <span>{setting}: </span>
            {componentSettings[setting].values.map((value: any, i: number) => (
              <React.Fragment key={`${setting}_${value}`}>
                <Inline
                  as="button"
                  extend={exampleSettingValueCSS({
                    active:
                      !!exampleSettings && exampleSettings[setting] === value,
                  })}
                  onClick={() =>
                    setExampleSettings({
                      ...exampleSettings,
                      [setting]: value,
                    })
                  }
                >
                  {value}
                </Inline>
                {i + 1 < componentSettings[setting].values.length ? ' | ' : ''}
              </React.Fragment>
            ))}
          </Text>
        ))}
      </div>
    </div>
  );
};

const wrapperCSS =
  ({
    background,
    maxWidth,
  }: {
    background: string;
    maxWidth: number;
  }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    position: 'relative',
    left: '0px',
    marginTop: baselineGrid * 5,
    maxWidth,
    background,
  });

const boxSpacerCSS = ({ ratio }: { ratio: number }): ExtendCSS => ({
  position: 'relative',
  paddingBottom: `${ratio * 100}%`,
  height: '0',
});

const innerWrapperCSS: ExtendCSS = {
  position: 'absolute',
  overflow: 'hidden',
  pointerEvents: 'none',
  top: '0',
  left: '0',
  height: '100%',
  width: '100%',
};

const exampleSettingsCSS: ExtendCSS = ({ theme: { color, baselineGrid } }) => ({
  marginTop: baselineGrid,
  marginRight: baselineGrid * 2,
  color: color.foreground.secondary,
});

const exampleSettingValueCSS =
  ({ active }: { active: boolean }): ExtendCSS =>
  ({ theme: { color, baselineSubGrid, baselineGrid } }) => ({
    background: 'transparent',
    border: '0',
    cursor: 'pointer',
    padding: `${baselineSubGrid}px ${baselineGrid}px`,
    borderRadius: 4,
    color: color.foreground.secondary,
    ...(active && {
      background: color.foreground.primary,
      color: color.background.primary,
    }),
    ':hover': {
      background: active ? color.foreground.primary : color.ornament.divider,
    },
  });

/**
 * Example area ruler component
 */
const Ruler: React.FC<
  React.PropsWithChildren<{ direction: 'horizontal' | 'vertical' }>
> = ({ direction, children }) => (
  <Block
    extend={direction === 'horizontal' ? rulerHorizontalCSS : rulerVerticalCSS}
  >
    <RulerArrow direction={direction === 'horizontal' ? 'left' : 'up'} />
    <div>{children} px</div>
    <RulerArrow direction={direction === 'horizontal' ? 'right' : 'down'} />
  </Block>
);

const rulerStyleBase: ExtendCSS = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
};

const rulerTextBase: ExtendCSS = {
  flex: '0 0 auto',
  padding: '0 6px',
  background: 'white',
  fontSize: '9px',
  letterSpacing: '.075em',
  whiteSpace: 'nowrap',
  zIndex: 10,
};

const rulerHorizontalCSS: ExtendCSS = ({ theme: { color } }) => ({
  ...rulerStyleBase,
  width: '100%',
  top: '-17px',
  color: color.foreground.secondary,
  '> div': {
    flex: '1 1 auto',
  },
  '> span': {
    ...rulerTextBase,
  },
});

const rulerVerticalCSS: ExtendCSS = ({ theme: { color, baselineGrid } }) => ({
  ...rulerStyleBase,
  flexDirection: 'column',
  height: '100%',
  width: '11px',
  left: '-16px',
  color: color.foreground.secondary,
  '> div': {
    flex: '1 1 auto',
  },
  '> span': {
    ...rulerTextBase,
    transform: 'rotate(-90deg)',
  },
});

/**
 * RulerArrow component
 */
const RulerArrow: React.FC<
  React.PropsWithChildren<{ direction: 'left' | 'right' | 'down' | 'up' }>
> = ({ direction }) => (
  <Block
    extend={{
      position: 'relative',
      display: 'flex',
      opacity: 0.4,
      ...((direction === 'left' || direction === 'right') && {
        height: '100%',
      }),
      ...((direction === 'up' || direction === 'down') && {
        width: '100%',
        justifyContent: 'center',
      }),
      ':after': {
        content: '""',
        ...((direction === 'left' || direction === 'right') && {
          borderBottom: '1px solid currentColor',
          width: '100%',
        }),
        ...((direction === 'up' || direction === 'down') && {
          borderLeft: '1px solid currentColor',
        }),
      },
      ':before': {
        content: '""',
        position: 'absolute',
        borderTop: '1px solid currentColor',
        borderRight: '1px solid currentColor',
        height: '5px',
        width: '5px',
        ...(direction === 'left' && {
          top: '-2px',
          left: '0',
          transform: 'rotate(-135deg)',
        }),
        ...(direction === 'right' && {
          top: '-2px',
          right: '0',
          transform: 'rotate(45deg)',
        }),
        ...(direction === 'up' && {
          left: '50%',
          marginLeft: '-3px',
          top: '0',
          transform: 'rotate(-45deg)',
        }),
        ...(direction === 'down' && {
          left: '50%',
          marginLeft: '-3px',
          bottom: '0',
          transform: 'rotate(-225deg)',
        }),
      },
    }}
  />
);
