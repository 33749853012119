// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ExtendCSS, Block, Text } from 'vcc-ui';
import { Link } from '@vcc-www/buttons';
import { AssetExample } from './AssetExample';
import { AssetSpecificationType } from './types';

export const AssetSpecificationBlock: React.FC<
  React.PropsWithChildren<AssetSpecificationType>
> = ({ title, assets = [] }) => {
  const assetCount = assets.length;
  const variationsCount = assets
    .map((a) => a.variations.length)
    .reduce((p, c) => p + c);

  return (
    <Block extend={wrapperCSS}>
      <Text as="h1" variant="ootah" subStyle="emphasis">
        {title}
      </Text>
      <Text as="p" variant="columbus" extend={infoCSS}>
        {assetCount} {`asset${assetCount !== 1 ? 's' : ''}`}
        {' with '}
        {variationsCount} {`variation${variationsCount !== 1 ? 's' : ''}`}
      </Text>
      {assets.map((asset) =>
        asset.variations.map((variation, i) => (
          <Block key={variation.name} extend={sectionCSS}>
            <Block as="h2" extend={subHeaderWrapperCSS}>
              <Text variant="hillary" extend={preSubHeaderCSS}>
                {asset.name && `${asset.name} / `}
              </Text>
              <Text variant="hillary" extend={subHeaderCSS} subStyle="emphasis">
                {variation.name}
              </Text>
            </Block>
            <SpecTable
              items={[
                { key: 'format', value: variation.format },
                { key: 'file name', value: variation.fileName },
                {
                  key: 'resolution',
                  value: `${variation.resolution[0]} × ${variation.resolution[1]}`,
                },
              ]}
            />
            {/^(png|jpg|jpeg)$/.test(variation.format) && (
              <Link
                href="#download-placeholder"
                onClick={(event) => {
                  event.preventDefault();
                  generatePlaceholder({
                    componentName: title,
                    assetName: asset.name || '',
                    variationName: variation.name,
                    fileName: variation.fileName,
                    resolution: variation.resolution,
                    format: variation.format,
                  });
                }}
              >
                Download placeholder
              </Link>
            )}
            {variation.description && (
              <Text as="p" variant="kelly" extend={descriptionCSS}>
                {variation.description}
              </Text>
            )}
            <AssetExample
              {...variation.example}
              componentSettings={variation.example?.componentSettings || {}}
              resolution={variation.resolution}
            />
          </Block>
        )),
      )}
    </Block>
  );
};

const wrapperCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  padding: baselineGrid * 2,
});

const sectionCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  marginTop: baselineGrid * 6,
});

const infoCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.secondary,
});

const subHeaderWrapperCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  margin: `${baselineGrid * 3}px 0`,
});

const preSubHeaderCSS: ExtendCSS = ({ theme: { color } }) => ({
  display: 'inline',
  color: color.foreground.secondary,
});

const subHeaderCSS: ExtendCSS = ({ theme: { color } }) => ({
  display: 'inline',
});

const descriptionCSS: ExtendCSS = ({ theme: { color, baselineGrid } }) => ({
  margin: `${baselineGrid * 3}px 0`,
  maxWidth: 600,
  color: color.foreground.secondary,
});

/**
 * Component for rendering a table of data.
 */
const SpecTable: React.FC<
  React.PropsWithChildren<{
    items: { key: string; value: string | number | boolean }[];
  }>
> = ({ items }) => (
  <Block as="table" extend={tableCSS}>
    <Block as="tbody" extend={tableBodyCSS}>
      {items.map(({ key, value }) => (
        <Block key={key} as="tr" extend={tableRowCSS}>
          <Text variant="kelly" as="td" extend={tableCellCSS}>
            {key}
          </Text>
          <Text
            variant="kelly"
            subStyle="emphasis"
            as="td"
            extend={tableCellCSS}
          >
            {value}
          </Text>
        </Block>
      ))}
    </Block>
  </Block>
);

const tableCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  display: 'table',
  borderSpacing: 0,
  margin: `${baselineGrid * 3}px 0`,
});

const tableBodyCSS: ExtendCSS = () => ({
  display: 'table-row-group',
});

const tableRowCSS: ExtendCSS = () => ({
  display: 'table-row',
});

const tableCellCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  display: 'table-cell',
  paddingLeft: '0',
  verticalAlign: 'top',
  ':first-child': {
    paddingRight: baselineGrid * 3,
  },
});

const generatePlaceholder = ({
  componentName,
  assetName,
  variationName,
  fileName,
  resolution,
  format,
}: {
  componentName: string;
  assetName: string;
  variationName: string;
  fileName: string;
  resolution: [number, number];
  format: string;
}): void => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const placeholderFileName = fileName.replace(/<.*?>/, 'placeholder');

  canvas.width = resolution[0];
  canvas.height = resolution[1];

  if (ctx) {
    // Add background
    ctx.fillStyle = '#DE8787';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Start with a large font size
    let fontSize = 300;

    const posX = resolution[0] / 2;
    const posY = resolution[1] / 2;

    // Lower the font size until the text fits the canvas
    do {
      fontSize--;
      ctx.font = `${fontSize}px monospace`;
    } while (ctx.measureText(componentName).width > canvas.width * 0.5);

    // Add component name
    ctx.textAlign = 'center';
    ctx.fillStyle = '#FFFFFF';
    ctx.fillText(componentName, posX, posY - fontSize);

    // Add asset and variation name
    ctx.font = `${fontSize * 0.6}px monospace`;
    ctx.fillStyle = '#FFFFFF80';
    ctx.fillText(
      `${assetName} / ${variationName}`,
      posX,
      posY + fontSize * 0.1,
    );

    // Add resolution
    ctx.font = `${fontSize * 0.6}px monospace`;
    ctx.fillText(
      `${resolution[0]} × ${resolution[1]}`,
      posX,
      posY + fontSize * 1.2,
    );

    const link = document.createElement('a');

    link.download = placeholderFileName;

    if (/^(jpg|jpeg)$/.test(format)) {
      link.href = canvas.toDataURL('image/jpeg', 1);
    } else {
      link.href = canvas.toDataURL('image/png');
    }

    link.click();
  }
};
