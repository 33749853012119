'use client';
import { useEffect, useState } from 'react';
import { CONTROLLER_EXPOSURE_EVENT_NAME } from './constants';

/**
 * Custom hook that provides access to the site navigation controller.
 * The site navigation controller is a global object that manages the global navigation.
 *
 * This hook listens for the exposure of the site navigation controller and returns it once available.
 * It may return undefined initially if the site navigation controller is not yet available.
 * If the site navigation controller is not available, it returns undefined.
 *
 * @returns The site navigation controller object or undefined if not available.
 */
export const useSiteNavigationController = () => {
  const [controller, setController] = useState(
    typeof window !== 'undefined' ? window.siteNavigationController : undefined,
  );

  useEffect(() => {
    if (controller) {
      return;
    }

    if (window.siteNavigationController) {
      setController(window.siteNavigationController);
      return;
    }

    const handleControllerExposed = () => {
      setController(window.siteNavigationController);

      window.removeEventListener(
        CONTROLLER_EXPOSURE_EVENT_NAME,
        handleControllerExposed,
      );
    };

    window.addEventListener(
      CONTROLLER_EXPOSURE_EVENT_NAME,
      handleControllerExposed,
    );

    return () => {
      window.removeEventListener(
        CONTROLLER_EXPOSURE_EVENT_NAME,
        handleControllerExposed,
      );
    };
  }, [controller]);

  return controller;
};
