import { useMemo, useState } from 'react';

export const useCarouselFilters = () => {
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [filters, setFilters] = useState<string[]>([]);
  const updateFilter = useMemo(
    () => (val: string | undefined) => setFilter(val),
    [],
  );

  return {
    filter,
    updateFilter,
    filters,
    setFilters,
  };
};
