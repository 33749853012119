'use client';

import { useEffect } from 'react';

import type { ProvokeErrorProps } from './provokeErrorServer';
import { ErrorType, ProvokedErrorMessage, today } from './provokeErrorServer';

const ProvokeErrorClient = (props: ProvokeErrorProps) => {
  const { errorType } = props;

  useEffect(() => {
    if (errorType !== ErrorType.Client) return;
    throw new Error(`${ProvokedErrorMessage} [${errorType}] [${today()}]`);
  }, [errorType]);

  return null;
};

export default ProvokeErrorClient;
