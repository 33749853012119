import React from 'react';
import CoreCarousel from '../../../index';
import styles from '../Carousel.module.css';
import { cssJoin } from '@volvo-cars/css/utils';
import CarouselHeading from './CarouselHeading';

export const CarCarouselSkeleton = ({
  title,
  centerAlign,
  subTitle,
}: {
  title?: string;
  centerAlign?: boolean;
  subTitle?: string;
}) => {
  return (
    <div className="stack-s">
      {title !== undefined && (
        <div
          className={cssJoin(
            'stack-16',
            centerAlign ? 'text-center' : 'text-start',
          )}
        >
          <CarouselHeading headingLevel="h2">{title}</CarouselHeading>
          {subTitle && <p className="text-secondary">{subTitle}</p>}
        </div>
      )}
      <CoreCarousel.Skeleton
        className={cssJoin(
          'p-24 gap-24 flex flex-col justify-between flex-shrink-0',
          styles.card_wrapper,
        )}
      >
        <CoreCarousel.SkeletonPart lineCount={0} />
        <CoreCarousel.SkeletonPart
          className="aspect-21/9 w-xs"
          style={{ maxWidth: '240px' }}
        />
        <div className="w-full bg-inherit">
          <CoreCarousel.SkeletonPart className="font-20" />
          <CoreCarousel.SkeletonPart className="mt-8" />
          <CoreCarousel.SkeletonPart className="mt-8" />
        </div>
      </CoreCarousel.Skeleton>
    </div>
  );
};
