'use client';

import React from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { IconButton } from '@volvo-cars/react-icons';
import { useCarousel } from '../providers/CarouselProvider';
import { Track } from '@volvo-cars/tracking';

const CarouselNavigation = ({
  className,
  customPrevClick,
  customNextClick,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  customPrevClick?: () => void;
  customNextClick?: () => void;
}) => {
  const {
    nextButtonProps,
    previousButtonProps,
    carouselIsScrollable,
    scrollPercentage,
  } = useCarousel();
  const { onClick: nextOnClick, ...restNextButtonProps } = nextButtonProps;
  const { onClick: prevOnClick, ...restPrevButtonProps } = previousButtonProps;

  return (
    <div
      className={cssMerge(
        'flex gap-8',
        !carouselIsScrollable && 'hidden',
        className,
      )}
      {...props}
    >
      <Track eventAction="arrow|previous" eventLabel="previous">
        <IconButton
          icon="chevron-back"
          variant="filled"
          onClick={customPrevClick || prevOnClick}
          data-carousel-navigation="prev"
          data-autoid="carousel-prev-button"
          {...restPrevButtonProps}
          disabled={Math.abs(scrollPercentage) < 1}
        />
      </Track>
      <Track eventAction="arrow|next" eventLabel="next">
        <IconButton
          icon="chevron-forward"
          variant="filled"
          data-carousel-navigation="next"
          data-autoid="carousel-next-button"
          onClick={customNextClick || nextOnClick}
          {...restNextButtonProps}
          disabled={Math.abs(scrollPercentage) > 99}
        />
      </Track>
    </div>
  );
};

export default CarouselNavigation;
