'use client';

import { useEffect, useState } from 'react';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import {
  CarModelYearMapping,
  getCarsForCarousel,
} from '../utils/getCarsForCarousel';
import { CarouselCarData } from '../types/carouselTypes';

export const useCarsForCarousel = (
  stringOfCarModels: string[],
  recommendedCars: boolean,
  colorMapping: Record<string, string>,
  carModelYearMapping: CarModelYearMapping = {},
  cacheTags?: string[],
) => {
  const { siteSlug } = useCurrentMarketSite();
  const [cars, setCars] = useState<CarouselCarData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const adjustedSiteSlug = siteSlug === 'master' ? 'intl' : siteSlug;
  if (!adjustedSiteSlug) {
    throw new Error('No site slug found');
  }

  useEffect(() => {
    const fetchCars = async () => {
      if (cars.length === 0) {
        try {
          setLoading(true);
          const fetchedCars = await getCarsForCarousel(
            adjustedSiteSlug,
            colorMapping,
            recommendedCars,
            stringOfCarModels,
            carModelYearMapping,
            cacheTags,
          );
          setCars(fetchedCars);
        } catch (err) {
          if (err instanceof Error) {
            setError(err);
          } else {
            setError(new Error('An unknown error occurred'));
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCars();
  }, [
    adjustedSiteSlug,
    colorMapping,
    recommendedCars,
    stringOfCarModels,
    carModelYearMapping,
    cars.length,
    cacheTags,
  ]);

  return { cars, isLoading: loading, error, siteSlug: adjustedSiteSlug };
};
