import React from 'react';
import ClickableDiscoveryCardContent from './clickable-discovery-card.part.content';
import { LinkEntry } from '@volvo-cars/content-management-client';
import ClickableDiscoveryCard from '.';
import {
  CardImageType,
  ClickableCardContentType,
  ClickableDiscoveryCardTemplateProps,
} from '../utils/types';
import { cssMerge } from '@volvo-cars/css/utils';
import styles from '../discovery-card.module.css';
import Card from '../parts';

type Props = ClickableDiscoveryCardTemplateProps<
  CardImageType & {
    cta: LinkEntry;
    topHeading: string;
    topDescription: string;
  }
>;

export const ClickableDiscoveryCardInline = ({
  responsiveImages,
  cta,
  topHeading,
  topDescription,
  children,
  ...rest
}: Props) => (
  <ClickableDiscoveryCard className={cssMerge('p-24', styles.inline)} cta={cta}>
    <div slot="top" className="mb-24">
      <hgroup className="stack-text">
        <h2 className="font-20 font-medium">{topHeading}</h2>
        {topDescription && <p className="font-16">{topDescription}</p>}
      </hgroup>
    </div>
    <Card.Image
      responsiveImages={{
        ...{ smAspectRatio: '4:3' },
        ...responsiveImages,
      }}
    />
    {children ? (
      <div className="mt-24" slot="main">
        {children}
      </div>
    ) : (
      <ClickableDiscoveryCardContent {...(rest as ClickableCardContentType)} />
    )}
  </ClickableDiscoveryCard>
);
