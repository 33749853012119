import {
  GetSearchParamsInObjectFormProps,
  GetTokenFromPathnameProps,
} from './router-params.types';

export const getSearchParamsInObjectForm = ({
  searchParams,
}: GetSearchParamsInObjectFormProps) => {
  const searchParamsInObjectForm = Object.fromEntries(
    new URLSearchParams(searchParams?.toString()),
  );
  return searchParamsInObjectForm;
};

export const getTokenFromPathname = ({
  pathname,
}: GetTokenFromPathnameProps) => {
  // The token is the last segment of the pathname
  const token = pathname.split('/').pop();
  return token;
};
