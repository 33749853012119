export const getModelSlugFromKey = (modelId?: string | null): string => {
  if (typeof modelId !== 'string') return '';
  if (modelId.endsWith('-phev')) {
    return modelId.replace(/-phev$/, '-hybrid');
  } else if (modelId.endsWith('-bev')) {
    return modelId.replace(/-bev$/, '-electric');
  } else if (modelId.endsWith('-cc')) {
    return modelId.replace(/-cc$/, '-cross-country');
  } else if (modelId.endsWith('-ice')) {
    return modelId.replace(/-ice$/, '');
  }
  return modelId;
};
