'use client';
import { useCallback, useMemo } from 'react';
import { getFleetVolvoIdLoginLink } from '@vcc-package/fleet-sales/src/getFleetVolvoIdLoginLink';
import {
  FLEET_BUSINESS_SOURCE_ID,
  GetCtaFromMarketProps,
  UseVolvoIdLinksProps,
} from './volvo-id-links.types';
import { getEnv } from '../../utils/get-env/get-env.utils';
import { applyTemplate } from '@vcc-www/utils/stringTemplate';
import { getCtaFromMarket, getHrefFromFleetUrl } from './volvo-id-links.utils';
import { usePathname } from 'next/navigation';

export const useVolvoIdLinks = ({
  market,
  marketCTAs,
  deployEnv,
}: UseVolvoIdLinksProps) => {
  const path = usePathname();
  const { domain, host } = getEnv({ deployEnv });

  const hrefFromFleetUrl = useCallback(
    (fallbackHref: string, path?: string) =>
      getHrefFromFleetUrl({
        fallbackHref,
        path,
        market,
        domain,
      }),
    [domain, market],
  );

  const ctaFromMarket = useCallback(
    ({ id }: Pick<GetCtaFromMarketProps, 'id'>) =>
      getCtaFromMarket({ id, marketCTAs }),
    [marketCTAs],
  );

  const signIn = useMemo(() => {
    const href = getFleetVolvoIdLoginLink(domain, path ?? '', market ?? '');
    const signInData = ctaFromMarket({ id: FLEET_BUSINESS_SOURCE_ID.SIGN_IN });

    return { ...signInData, link: { ...signInData?.link, href } };
  }, [domain, ctaFromMarket, market, path]);

  const learnMore = useMemo(() => {
    const learnMoreData = ctaFromMarket({
      id: FLEET_BUSINESS_SOURCE_ID.LEARN_MORE,
    });

    const href = hrefFromFleetUrl(learnMoreData?.link?.href ?? '');

    return {
      ...learnMoreData,
      link: {
        ...learnMoreData?.link,
        href,
      },
    };
  }, [ctaFromMarket, hrefFromFleetUrl]);

  const signUp = useMemo(() => {
    const signUpData = ctaFromMarket({ id: FLEET_BUSINESS_SOURCE_ID.SIGN_UP });
    const href = hrefFromFleetUrl(
      signUpData?.link?.href ?? '',
      '/create-volvo-id',
    );

    return {
      ...signUpData,
      link: {
        ...signUpData?.link,
        href,
      },
    };
  }, [ctaFromMarket, hrefFromFleetUrl]);

  const contactUs = useMemo(() => {
    const contactUsData = ctaFromMarket({
      id: FLEET_BUSINESS_SOURCE_ID.CONTACT_US,
    });

    const href = applyTemplate(contactUsData?.link?.href ?? '', {
      qaOrProdDomain: host,
    });
    return {
      ...contactUsData,
      link: {
        ...contactUsData?.link,
        href,
      },
    };
  }, [ctaFromMarket, host]);

  return { signIn, learnMore, signUp, contactUs };
};
