'use client';
import { useCallback, useState } from 'react';
import { OrderError } from '../../utils/order-error/order-error';

// Use inside async functions to throw errors (eg. in useEffect)
export const useAsyncError = () => {
  const [, setError] = useState();
  return useCallback(
    (e: OrderError) => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};
