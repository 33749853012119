// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @volvo-cars/css/no-custom-class */
import React from 'react';
import { Block, Text, Click, ExtendCSS } from 'vcc-ui';

export class AssetViewer extends React.Component<any, any> {
  reader: FileReader | any = null;
  state: any = {
    image: null,
  };

  componentDidMount() {
    this.reader = new FileReader();

    this.reader.addEventListener(
      'load',
      (e: any) => {
        this.setState({ image: e.target.result });
      },
      false,
    );
  }

  onFileChange = (e: any) => {
    this.reader.readAsDataURL(e.target.files[0]);
  };

  onClear = () => {
    this.setState({ image: null });
  };

  render() {
    return (
      <Block extend={wrapperCSS}>
        {this.state.image && (
          <Block as="img" extend={imageCSS} alt="" src={this.state.image} />
        )}
        <Block className="asset-viewer-controls" extend={controlsCSS}>
          <Block as="label" extend={buttonCSS}>
            <Text as="span" variant="bates" extend={buttonTextCSS}>
              Preview asset
            </Text>
            <Block
              as="input"
              type="file"
              extend={hiddenInputCSS}
              onChange={this.onFileChange}
            />
          </Block>
          {this.state.image && (
            <Click extend={buttonCSS} onClick={this.onClear}>
              <Text as="span" variant="bates" extend={buttonTextCSS}>
                Clear
              </Text>
            </Click>
          )}
        </Block>
      </Block>
    );
  }
}

const wrapperCSS: ExtendCSS = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  ':hover > .asset-viewer-controls': {
    transform: 'translateY(0)',
    opacity: 1,
  },
};

const controlsCSS: ExtendCSS = ({
  theme: { baselineGrid, baselineSubGrid },
}) => ({
  position: 'absolute',
  zIndex: 100,
  top: baselineGrid,
  right: baselineGrid,
  opacity: 0,
  transform: `translateY(-${baselineSubGrid}px)`,
  transition: 'all 300ms',
  whiteSpace: 'nowrap',
});

const buttonCSS: ExtendCSS = ({
  theme: { baselineGrid, baselineSubGrid },
}) => ({
  display: 'inline-block',
  padding: `${baselineSubGrid}px ${baselineGrid}px`,
  color: 'white',
  background: 'rgba(0,0,0,0.5)',
  margin: `0 0 0 ${baselineGrid}px`,
  transition: 'all 300ms',
  borderRadius: '2px',
  cursor: 'pointer',
  verticalAlign: 'top',
  '::-webkit-file-upload-button': {
    visibility: 'hidden',
  },
  ':hover': {
    background: 'rgba(0,0,0,0.4)',
  },
});

const buttonTextCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.inverted,
});

const hiddenInputCSS: ExtendCSS = {
  height: 0,
  width: 0,
};

const imageCSS: ExtendCSS = {
  maxWidth: '100%',
  maxHeight: '100%',
};
