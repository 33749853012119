export const ProvokedErrorMessage = 'PROVOKED_TEST_ERROR!';

export enum ErrorType {
  Server = 'Server',
  Client = 'Client',
}

export const today = () => {
  return new Date().toLocaleString('sv');
};

export interface ProvokeErrorProps {
  errorType: ErrorType | undefined;
}

const ProvokeErrorServer = (props: ProvokeErrorProps) => {
  if (typeof window !== 'undefined') return null;
  const { errorType } = props;

  if (errorType === ErrorType.Server) {
    throw new Error(`${ProvokedErrorMessage} [${errorType}] [${today()}]`);
  }

  return null;
};

export default ProvokeErrorServer;
