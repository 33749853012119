import React, { HTMLAttributes } from 'react';
import Carousel from '../../index';
import { cssMerge } from '@volvo-cars/css/utils';
import styles from '../Carousel.module.css';

const Skeleton = ({
  className,
  style,
  children,
  cardCount,
  ...props
}: HTMLAttributes<HTMLAnchorElement> & { cardCount?: number }) => {
  const length = Array.from<number>({ length: cardCount ?? 9 });

  return (
    <Carousel.Core>
      <Carousel.Slider>
        {length.map((_, i) => (
          <Carousel.Card
            {...props}
            style={style}
            className={cssMerge(className, styles.skeleton_card)}
            key={`carousel-skeleton-item-${i}`}
          >
            {children}
          </Carousel.Card>
        ))}
      </Carousel.Slider>
    </Carousel.Core>
  );
};

export default Skeleton;
