import getRecommendedCars from '@vcc-www/utils/getRecommendedCars';
import { getMarketSite } from '@volvo-cars/market-sites';
import { CarouselGraphCarData } from '../types/graphTypes';
import carMapper from './carMapper';
import { fetchCarouselData } from './fetchCarouselData';

export type CarModelYearMapping = {
  [market: string]: { [carModel: string]: string };
};

export const getCarsForCarousel = async (
  siteSlug: string,
  colorMapping: Record<string, string>,
  recommendedCars?: boolean,
  carsToFetch?: string[],
  carModelYearMapping: CarModelYearMapping = {},
  cacheTags?: string[],
) => {
  const useRecommendedCars = Boolean(recommendedCars);
  const cars = carsToFetch || [];
  const { locale, regionCode, multilingual } = getMarketSite(siteSlug);

  const market = regionCode !== '001' ? regionCode : 'intl'; // Use intl for international site

  if (useRecommendedCars && market) {
    const recommendedCars = await getRecommendedCars({
      locale,
      market,
      lean: true,
    });
    recommendedCars.data.forEach(
      (car) => !cars.includes(car) && cars.push(car),
    );
  }

  const normalizedMapping = Object.keys(
    carModelYearMapping,
  ).reduce<CarModelYearMapping>((acc, key) => {
    acc[key.toLowerCase()] = carModelYearMapping[key];
    return acc;
  }, {});
  const normalizedMarket = market?.toLowerCase();

  const modelYearMappingForMarket =
    normalizedMarket &&
    normalizedMarket in normalizedMapping &&
    normalizedMapping[normalizedMarket];

  const response = await Promise.all(
    cars.map(
      (carModel) =>
        market &&
        fetchCarouselData({
          cacheTags,
          carModel,
          locale:
            multilingual && regionCode === 'US'
              ? locale.replace(/^[a-z]+-/g, 'en-')
              : locale,
          market,
          modelYear:
            modelYearMappingForMarket && carModel in modelYearMappingForMarket
              ? modelYearMappingForMarket[carModel]
              : '', // Fed. graph handles empty string as current model year
        }),
    ),
  );
  const carData = response
    .filter(
      (res): res is { carData: CarouselGraphCarData; warnings: [] } =>
        res?.carData !== undefined,
    )
    .map((res) => res.carData);
  const mappedCars = carData.map((car) => carMapper(car, colorMapping));
  return mappedCars;
};
