'use client';

import type React from 'react';
import { useState } from 'react';

import { useExitIntent } from '@vcc-package/leads-utils/hooks';

type ExitIntentProps = {
  componentId: string;
  children: React.ReactNode;
};

const ExitIntent = ({ componentId, children }: ExitIntentProps) => {
  const [showContent, setShowContent] = useState(false);

  useExitIntent({
    componentId,
    onExit: () => {
      if (showContent) return;

      setShowContent(true);
    },
  });

  return showContent ? children : null;
};

export default ExitIntent;
