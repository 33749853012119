import { useCallback, useEffect, useState } from 'react';

export const useCarouselScrollHandlers = (
  carouselRef: React.RefObject<HTMLDivElement | null>,
) => {
  const carousel = carouselRef.current;
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = useCallback(() => {
    if (carousel) {
      const scroll = calculateScrollPercentage(carousel);
      setScrollPercentage(!Number.isNaN(scroll) ? scroll : 0);
    }
  }, [carousel]);

  useEffect(() => {
    if (carousel) {
      carousel.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (carousel) {
        carousel.removeEventListener('scroll', handleScroll);
      }
    };
  }, [carousel, handleScroll]);

  return { scrollPercentage };
};

const calculateScrollPercentage = (carousel: HTMLDivElement) => {
  const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;

  const currentScroll = carousel.scrollLeft;

  const scrollPercent = (currentScroll / maxScrollLeft) * 100;

  return scrollPercent;
};
