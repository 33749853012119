import { getVolvoIdLoginLink } from '@vcc-www/auth-client/volvo-id/getVolvoIdLoginLink';

export const getFleetVolvoIdLoginLink = (
  baseUrl: string,
  currentPath: string,
  siteSlug: string,
  createAccountUrl?: string,
): string => {
  const defaultCreateAccountURL = `${
    baseUrl ?? origin
  }/${siteSlug}/business/fleet/create-volvo-id`;

  const currentPathWithSession = `${baseUrl}/api/fleet/external/${siteSlug}/set-session?redirectUrl=${encodeURIComponent(
    baseUrl + currentPath,
  )}`;

  return getVolvoIdLoginLink({
    baseUrl,
    currentPath: currentPathWithSession,
    siteSlug,
    relayFlags: {
      createAccountURL: createAccountUrl ?? defaultCreateAccountURL,
      hideInfoLink: true,
    },
    webPlatformAuthService: true,
  });
};
