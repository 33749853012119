'use client';
import { DiscoveryCardStacked } from '@vcc-package/discovery';
import { Dialog, DialogMain } from '@vcc-package/overlays';
import React, { useState } from 'react';
import ContentTypeProps from '../content-management/editorial-components/MerchandisingPromotion/merchandisingPromotion.props';
import styles from './merchandising.module.css';
import { cssJoin } from '@volvo-cars/css/utils';
import { useFeatureFlags } from '@vcc-www/feature-flags';
import { Markdown } from '@vcc-package/text';
import { Track } from '@volvo-cars/tracking';

type Props = {
  overlayContent: ContentTypeProps['content']['overlay'];
  ctaText: ContentTypeProps['content']['ctaText'];
  dialogTitle?: string;
};

const MerchandisingOverlay = ({
  overlayContent,
  ctaText,
  dialogTitle,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { globalFlags, loading: flagsLoading } = useFeatureFlags();

  const { offerCards, title, disclaimer } = overlayContent;

  // Get button CTA style className safely. Any misspelling or issues with the
  // flag, default to an outlined button.
  const ctaClassName =
    {
      'button-outlined': 'button-outlined',
      'button-text': 'button-text',
    }[globalFlags.merchandisingPromoCtaStyle] || 'button-outlined';

  return (
    <>
      {/*
        Not ideal, hiding the CTA until flags have loaded. The other option is
        having the CTA potentially swap from outlined to text, which feels worse.
        Until reliably fetching feature flags server side in edge workers we
        are advised to fetch them client side though.

        If a consuming app is lacking a FeatureFlagsProvider, loading will always
        be true. Therefore, check agains an empty flags object as well to not end
        up in a situation where we don't render the CTA.
      */}
      {(!flagsLoading || Object.keys(globalFlags).length === 0) && (
        <Track eventLabel="merchandising-initiative|open-overlay">
          <button
            onClick={() => setIsOpen(true)}
            className={ctaClassName}
            type="button"
          >
            {ctaText}
          </button>
        </Track>
      )}
      <Dialog
        title={dialogTitle}
        className="w-lg"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogMain>
          {title && <h2 className="font-16 text-secondary mb-24">{title}</h2>}
          {offerCards && offerCards.length > 0 && (
            <div
              className={cssJoin(
                styles['cards-grid'],
                'grid lg:grid-cols-2 gap-16',
              )}
            >
              {offerCards.map((card) => {
                if (
                  !card.responsiveImages ||
                  Object.keys(card.responsiveImages).length < 1
                ) {
                  return null;
                }

                return (
                  <DiscoveryCardStacked
                    key={card.heading}
                    responsiveImages={card.responsiveImages}
                  >
                    <div className="stack-text">
                      <h2>{card.heading}</h2>
                      {card.label && (
                        <p className="font-medium font-20">{card.label}</p>
                      )}
                      {card.bodyText && <p>{card.bodyText}</p>}
                      {card.cta && (
                        <a className="button-text" href={card.cta.href}>
                          {card.cta.text}
                        </a>
                      )}
                    </div>
                  </DiscoveryCardStacked>
                );
              })}
            </div>
          )}
          {disclaimer && (
            <Markdown
              className="micro text-secondary mt-16"
              markdown={disclaimer}
            />
          )}
        </DialogMain>
      </Dialog>
    </>
  );
};

export default MerchandisingOverlay;
