import { useEffect, useLayoutEffect } from 'react';
import { calculateTranslateXPixelValue } from '../../../utils/calculateTranslateXPixelValue';

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const useFullWidthCarousel = (
  carouselRef: React.RefObject<HTMLDivElement | null>,
  bleed: boolean,
) => {
  const carousel = carouselRef.current;

  useIsomorphicLayoutEffect(() => {
    if (!carousel || !bleed) return;

    const updateCarouselPadding = () => {
      const padding = calculateTranslateXPixelValue(carousel);
      carousel.style.setProperty('--full-width-padding', `${padding}px`);
    };

    // To get 100vw without scrollbar, otherwise it will overflow and cause scrollbar to appear
    const updateScrollbarWidthVariable = () => {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      carousel.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    };

    updateScrollbarWidthVariable();
    updateCarouselPadding();

    window.addEventListener('resize', updateCarouselPadding);

    return () => {
      window.removeEventListener('resize', updateCarouselPadding);
    };
  }, [carousel, bleed]);
};
