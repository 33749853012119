export function filterCarColorsMapping(originalObject: {
  [key: string]: string;
}): Record<string, string> {
  return Object.keys(originalObject)
    .filter((key) => key.startsWith('CarColors.colorMapping'))
    .reduce(
      (obj, key) => {
        obj[key] = originalObject[key];
        return obj;
      },
      {} as Record<string, string>,
    );
}
