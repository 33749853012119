'use client';
import React from 'react';
import Carousel from '@vcc-package/carousel';
import financeOptionsCarouselProps from '../../content-management/editorial-components/FinanceOptionsCarousel/FinanceOptionsCarousel.props';
import { FinanceOptions } from '@vcc-www/api/finance-options';
import { ClickableDiscoveryCardInline } from '@vcc-package/discovery';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import styles from './FinanceOptionsCarousel.module.css';
import { cssJoin } from '@volvo-cars/css/utils';

export interface FinanceOptionsCarouselClientProps {
  financeOptionsCarouselEntry: financeOptionsCarouselProps;
  financeOptionsGraphData: FinanceOptions;
}

export const FinanceOptionsCarouselClient = ({
  financeOptionsCarouselEntry,
  financeOptionsGraphData,
}: FinanceOptionsCarouselClientProps) => {
  const {
    content: { title, salesModels },
    showPaymentMethodAndContractLength,
    showAnnualMileage,
    showPrepayment,
    showExcessFees,
    showSettlementOption,
  } = financeOptionsCarouselEntry;

  const translate = useSharedComponentsTranslate();

  return (
    <div className="container">
      <h2>{title}</h2>
      <Carousel.Core>
        <Carousel.Navigation className="justify-end" />
        <Carousel.Slider className="mt-24 mb-32">
          {salesModels.map(({ salesModel }, index) => {
            return (
              <Carousel.Card
                className={cssJoin(styles['finance-options-card'], 'flex')}
                key={index}
              >
                <ClickableDiscoveryCardInline
                  className="h-full"
                  topHeading={
                    financeOptionsGraphData[salesModel]?.displayName || ''
                  }
                  topDescription={
                    financeOptionsGraphData[salesModel]?.endOfContract || ''
                  }
                  responsiveImages={{
                    sm: {
                      src:
                        financeOptionsGraphData[salesModel]?.image ||
                        'https://placehold.co/375x468',
                      alt: 'placeholder',
                    },
                  }}
                  cta={{
                    href: translate(
                      `FinanceOptionsCards.cta.${salesModel}_link`,
                    ),
                  }}
                >
                  {showPaymentMethodAndContractLength && (
                    <p className="font-20">
                      {`${financeOptionsGraphData[salesModel]?.paymentMethod}, ${financeOptionsGraphData[salesModel]?.contractTerm}`}
                    </p>
                  )}
                  <div className="text-secondary pt-8">
                    {showAnnualMileage && (
                      <p>
                        {financeOptionsGraphData[salesModel]?.annualMileage}
                      </p>
                    )}
                    {showPrepayment && (
                      <p>{financeOptionsGraphData[salesModel]?.prepayment}</p>
                    )}
                    {showExcessFees && (
                      <p>{financeOptionsGraphData[salesModel]?.excessFees}</p>
                    )}
                    {showSettlementOption && (
                      <p>
                        {financeOptionsGraphData[salesModel]?.settlementOption}
                      </p>
                    )}
                  </div>
                </ClickableDiscoveryCardInline>
              </Carousel.Card>
            );
          })}
        </Carousel.Slider>
        <Carousel.Indicator />
      </Carousel.Core>
    </div>
  );
};
