import React from 'react';
import { unescapeHTML } from '@vcc-www/utils/unescapeHtml';
import { Icon } from '@volvo-cars/react-icons';
import { cssJoin } from '@volvo-cars/css/utils';

const DiscoveryCardPartContent = ({
  heading,
  bodyText,
  hideBodyTextOnMobile,
  hideBodyTextOnTablet,
}: {
  heading?: string;
  bodyText?: string;
  hideBodyTextOnMobile?: boolean;
  hideBodyTextOnTablet?: boolean;
}) => (
  <div slot="main">
    {heading && (
      <div className="flex justify-between items-center gap-4">
        <h2 className="font-20 font-medium">{heading}</h2>
        <Icon icon="chevron-forward" size={16} />
      </div>
    )}
    {bodyText && (
      <p
        className={cssJoin(
          'text-secondary mt-16',
          hideBodyTextOnMobile && 'hidden md:block',
          hideBodyTextOnTablet && 'hidden lg:block',
        )}
      >
        {unescapeHTML(bodyText)}
      </p>
    )}
  </div>
);

export default DiscoveryCardPartContent;
