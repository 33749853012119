export const VOLVO_PROD_DOMAIN_COM_CN = 'www.volvocars.com.cn';
export const VOLVO_PROD_DOMAIN = 'www.volvocars.com';
export const VOLVO_PROD_SOURCE_DOMAIN = 'car-config.com';
export const VOLVO_PRE_PROD_DOMAIN = 'pre-prod.car-config.com';
export const VOLVO_QA_DOMAIN_CN = 'qawww.volvocars.com.cn';
export const VOLVO_QA_DOMAIN = 'qawww.volvocars.com';
export const VOLVO_TEST_DOMAIN = 'testwww.volvocars.com';
export const VOLVO_CAS_PROD_HOSTNAME = 'cas.volvocars.com';
export const VOLVO_CAS_PROD_CN_HOSTNAME = 'cas.volvocars.com.cn';
export const VOLVO_CAS_QA_HOSTNAME = 'qacas.volvocars.com';
export const VOLVO_CAS_QA_CN_HOSTNAME = 'qacas.volvocars.com.cn';

export const VOLVO_PROD_BASE_URL = 'https://www.volvocars.com';
export const VOLVO_QA_BASE_URL = 'https://qawww.volvocars.com';
export const VOLVO_TEST_BASE_URL = 'https://testwww.volvocars.com';
export const VOLVO_DEV_BASE_URL = 'http://localhost:3000';

export const VOLVO_PROD_AUTHORING_SOURCE_DOMAIN = 'authoring.volvocars.com';

export const VOLVO_TEST_SOURCE_DOMAINS = [
  'offer-selector.test-emea.ccdp-origin.io',
];
export const VOLVO_QA_SOURCE_DOMAINS = [
  'offer-selector.qa-emea.ccdp-origin.io',
  'offer-selector.qa.we.fe.ccdp.io',
];
export const VOLVO_PROD_SOURCE_DOMAINS = [
  'offer-selector.emea.ccdp-origin.io',
  'offer-selector.prod.we.fe.ccdp.io',
  'offer-selector.tm.ccdp-origin.io',
];
export const VOLVO_GLOBAL_DOMAINS = [
  VOLVO_PROD_DOMAIN_COM_CN,
  VOLVO_PROD_DOMAIN,
  VOLVO_QA_DOMAIN_CN,
  VOLVO_QA_DOMAIN,
  VOLVO_TEST_DOMAIN,
];

export const VOLVO_GLOBAL_HOSTS = [
  VOLVO_PROD_DOMAIN_COM_CN,
  VOLVO_PROD_DOMAIN,
  VOLVO_QA_DOMAIN_CN,
  VOLVO_QA_DOMAIN,
  VOLVO_TEST_DOMAIN,
];
export const PROD_HOSTS = [
  VOLVO_PROD_DOMAIN_COM_CN,
  VOLVO_PROD_DOMAIN,
  VOLVO_PROD_SOURCE_DOMAIN,
  ...VOLVO_PROD_SOURCE_DOMAINS,
];
export const AUTHORING_HOSTS = [VOLVO_PROD_AUTHORING_SOURCE_DOMAIN];
export const QA_HOSTS = [
  VOLVO_QA_DOMAIN_CN,
  VOLVO_QA_DOMAIN,
  ...VOLVO_QA_SOURCE_DOMAINS,
];
export const TEST_HOSTS = [VOLVO_TEST_DOMAIN, ...VOLVO_TEST_SOURCE_DOMAINS];
export const CAS_IMAGES_HOSTS = [
  VOLVO_CAS_PROD_HOSTNAME,
  VOLVO_CAS_PROD_CN_HOSTNAME,
  VOLVO_CAS_QA_HOSTNAME,
  VOLVO_CAS_QA_CN_HOSTNAME,
];
export const PR_ENVIRONMENT =
  'pr-\\d+-\\d+\\.westeurope\\.cloudapp\\.azure\\.com';
export const STORYBOOK = 'storybooks.ccdp.io';
export const LOCALHOST = 'localhost';
