import { gql } from 'graphql-request';

export const QUERY_FEATURED_DOCUMENTS_LIST = gql`
  query FeaturedDocumentsList(
    $marketSlug: ID!
    $language: [String!]
    $documentId: String!
  ) {
    market(id: $marketSlug) {
      contextualKnowledge(language: $language) {
        document(documentId: $documentId) {
          stringContent {
            title
            description
          }
          children {
            documentId
            stringContent {
              title
              description
            }
          }
        }
      }
    }
  }
`;
