import React from 'react';

export const getCarFilterKeyFromReactChild = (
  child: unknown,
): string[] | undefined => {
  if (!React.isValidElement(child)) {
    return undefined;
  }

  // We check both these props because data-filter-key is being used on client components and filterKey is being used on server components.
  const filter =
    findPropKeyInElement(child, 'data-filter-key') ??
    findPropKeyInElement(child, 'filterKey');
  if (!filter) {
    return undefined;
  }

  return typeof filter === 'string' ? [filter] : filter;
};

const findPropKeyInElement = (
  element: React.ReactNode,
  key: string,
  depth: number = 10,
): string | string[] | undefined => {
  if (depth === 0) return undefined;

  if (React.isValidElement(element)) {
    if (element.props && key in element.props) {
      const val = element.props[key];
      return typeof val === 'string' || Array.isArray(val) ? val : undefined;
    }

    if (element.props && element.props.children) {
      const children = React.Children.toArray(element.props.children);
      const child = children.find((child) =>
        findPropKeyInElement(child, key, depth - 1),
      );
      if (child) {
        return findPropKeyInElement(child, key, depth - 1);
      }
    }
  }

  return undefined;
};
