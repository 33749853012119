import React, { useCallback, useEffect } from 'react';

export const useHashTrigger = ({
  showMainDialogAndTrack,
  hashTrigger,
}: {
  showMainDialogAndTrack: (e?: React.MouseEvent<Element>) => void;
  hashTrigger?: string;
}) => {
  const firstLoad = React.useRef(true);

  useEffect(() => {
    if (typeof window === 'undefined' || !hashTrigger || !firstLoad.current) {
      return;
    }

    firstLoad.current = false;

    const handleHashChange = (event: HashChangeEvent) => {
      const newHash = event.newURL.split('#')[1];
      const oldHash = event.oldURL.split('#')[1];

      if (newHash === hashTrigger && newHash !== oldHash) {
        showMainDialogAndTrack();
      }
    };

    //Check first load
    if (window.location.hash.replace(/^#/, '') === hashTrigger) {
      showMainDialogAndTrack();
    }

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      firstLoad.current = true;
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [hashTrigger, showMainDialogAndTrack]);

  const hashTriggerCleanup = useCallback(() => {
    if (hashTrigger && typeof window !== 'undefined') {
      window.location.hash = '';
    }
  }, [hashTrigger]);

  return {
    hashTriggerCleanup,
  };
};
