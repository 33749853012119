import { getMarketSite } from '@volvo-cars/market-sites';
import { VolvoIdRelayFlags } from './types';

export const getVolvoIdLoginLink = ({
  baseUrl,
  currentPath,
  siteSlug,
  scopes,
  relayFlags,
  webPlatformAuthService,
}: {
  baseUrl: string;
  currentPath: string;
  siteSlug: string;
  scopes?: string[];
  relayFlags?: VolvoIdRelayFlags;
  webPlatformAuthService?: boolean;
}): string => {
  const { languageCode, regionCode } = getMarketSite(siteSlug as string);
  const url = new URL(
    webPlatformAuthService
      ? `${baseUrl}/api/auth/authservice/login/volvoid`
      : `${baseUrl}/intl/auth/authservice/login/volvoid`,
  );

  url.searchParams.set('redirect', new URL(currentPath, baseUrl).toString());
  url.searchParams.set('language', languageCode);
  if (scopes) {
    url.searchParams.set('scope', scopes.join(' '));
  }
  if (regionCode) {
    url.searchParams.set('market', regionCode);
  }
  if (relayFlags) {
    url.searchParams.set('relayFlags', JSON.stringify(relayFlags));
  }

  return url.toString();
};
