'use client';
import { useEffect } from 'react';

import throttle from 'lodash/throttle';
import { isStorybook } from '@vcc-www/constants/config';
import { useLocalStorage } from '@vcc-www/hooks';

const LOCAL_STORAGE_KEY = 'exitIntentTriggered';

type useExitIntentProps = {
  componentId: string;
  onExit: () => void;
};

export const useExitIntent = ({ componentId, onExit }: useExitIntentProps) => {
  const [exitIntentTriggered, setExitIntentTriggered] = useLocalStorage<
    string[]
  >(LOCAL_STORAGE_KEY, []);

  useEffect(() => {
    const isTouchDevice = window.matchMedia('(pointer: coarse)').matches;
    const earlyExit =
      isTouchDevice || exitIntentTriggered.includes(componentId);

    if (earlyExit && !isStorybook) return;

    const pointerLeavingScreenCheck = throttle((ev) => {
      if (
        ev.clientY > 0 ||
        (exitIntentTriggered.includes(componentId) && !isStorybook)
      ) {
        return;
      }

      if (!exitIntentTriggered.includes(componentId)) {
        setExitIntentTriggered([...exitIntentTriggered, componentId]);
      }

      onExit();
      removeEvents();
    }, 200);

    const removeEvents = () => {
      document.removeEventListener('mouseout', pointerLeavingScreenCheck);
    };

    document.addEventListener('mouseout', pointerLeavingScreenCheck);
    console.log('ExitIntent: mouseout event listener added');

    return () => removeEvents();
  }, [onExit, exitIntentTriggered, setExitIntentTriggered, componentId]);
};
