import {
  PROD_HOSTS,
  QA_HOSTS,
  VOLVO_DEV_BASE_URL,
  VOLVO_GLOBAL_HOSTS,
  VOLVO_PRE_PROD_DOMAIN,
  VOLVO_PROD_BASE_URL,
  VOLVO_PROD_DOMAIN,
  VOLVO_QA_BASE_URL,
  VOLVO_QA_DOMAIN,
  VOLVO_TEST_BASE_URL,
} from '../../constants/urls';
import { getTypeOfBrowser } from '../type-of-browser/get-type-of-browser.utils';
import { DEPLOY_ENVS, DeployEnv } from './get-env.types';

export const getEnv = ({
  deployEnv,
  clientName,
}: { deployEnv?: DeployEnv; clientName?: string } | undefined = {}) => {
  // Fallback to window if deployEnv is not provided
  const hostname =
    typeof window !== 'undefined' ? window.location.hostname : '';
  const { isServer } = getTypeOfBrowser();
  const isGlobalHost = VOLVO_GLOBAL_HOSTS.includes(hostname);
  const isProdHost = deployEnv
    ? deployEnv === 'prod'
    : [...PROD_HOSTS, VOLVO_PRE_PROD_DOMAIN].includes(hostname);
  const isQAHost = deployEnv ? deployEnv === 'qa' : QA_HOSTS.includes(hostname);
  const isDev = deployEnv === 'dev' && clientName === 'offer-selector';
  const host = isQAHost ? VOLVO_QA_DOMAIN : VOLVO_PROD_DOMAIN;
  const localURL = isServer ? 'http://localhost:3000' : '';
  const domain = isDev
    ? localURL
    : isGlobalHost
      ? `https://${hostname}`
      : `https://${host}`;

  let baseUrl = '';
  switch (deployEnv) {
    case DEPLOY_ENVS.TEST:
      baseUrl = VOLVO_TEST_BASE_URL;
      break;
    case DEPLOY_ENVS.QA:
      baseUrl = VOLVO_QA_BASE_URL;
      break;
    case DEPLOY_ENVS.DEV:
      baseUrl = VOLVO_DEV_BASE_URL;
      break;
    default:
      baseUrl = VOLVO_PROD_BASE_URL;
      break;
  }

  return {
    baseUrl,
    domain,
    isProdHost,
    isQAHost,
    isGlobalHost,
    host,
  };
};
