import React from 'react';

type Props = { label: string };

const Badge: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  ...props
}) => {
  return (
    <small
      style={{
        display: 'inline-block',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: '2px 12px',
      }}
      className="micro font-medium overflow-hidden border rounded bg-secondary uppercase"
      {...props}
    >
      {label}
    </small>
  );
};

export default Badge;
