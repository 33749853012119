'use client';

import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';

export interface CarInfoProps {
  engineType: string;
  body: string;
}
export const useCarouselDictionaries = (
  carInfoProps?: CarInfoProps,
): Record<string, string> => {
  const translate = useSharedComponentsTranslate();

  const ctaText = translate('ModelComparison.entrypoint.ctaText');
  const carInfo = translate('Carousal.labels.carInfo', {
    engineType: carInfoProps?.engineType,
    bodyType: carInfoProps?.body,
  });
  return { ctaText, carInfo };
};
