import cta from '@vcc-package/offers-utils/content-management/content-types/content/cta/Cta.props';
import { DeployEnv } from '@vcc-package/offers-utils/utils/get-env/get-env.types';
import { getEnv } from '../../utils/get-env/get-env.utils';
import { usePathname } from 'next/navigation';

export type UseVolvoIdLinksProps = {
  market: string;
  marketCTAs: { [sourceId: string]: cta };
  deployEnv?: DeployEnv;
};

export const FLEET_BUSINESS_TARGETS = {
  BLANK: '_blank',
  SELF: '_self',
  MODAL: 'modal',
  EXTERNAL: 'external',
} as const;

export const FLEET_BUSINESS_SOURCE_ID = {
  LEARN_MORE: 'fleetLearnMore',
  SIGN_IN: 'fleetSignIn',
  SIGN_UP: 'fleetSignUp',
  CONTACT_US: 'fleetContactUs',
} as const;

export type FleetBusinessSourceId =
  (typeof FLEET_BUSINESS_SOURCE_ID)[keyof typeof FLEET_BUSINESS_SOURCE_ID];

export type GetHrefFromFleetUrlProps = Pick<UseVolvoIdLinksProps, 'market'> &
  Pick<ReturnType<typeof getEnv>, 'domain'> & {
    fallbackHref: string;
    path?: ReturnType<typeof usePathname>;
  };

export type GetCtaFromMarketProps = Pick<UseVolvoIdLinksProps, 'marketCTAs'> & {
  id: FleetBusinessSourceId;
};
