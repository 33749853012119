import React, { useState, useCallback } from 'react';

type ContextType = {
  overlayIsOpen: boolean;
  setOverlayIsOpen: (isOpen: boolean) => void;
  openOverlay: () => void;
};

type ProviderProps = {
  overlayIsOpen?: boolean;
  children: (value: ContextType) => React.ReactNode;
};

const initialContext: ContextType = {
  overlayIsOpen: false,
  setOverlayIsOpen: () => {},
  openOverlay: () => {},
};

export const Context: React.Context<ContextType> =
  React.createContext(initialContext);

const CalloutsOverlayProvider: React.FC<ProviderProps> = ({
  children,
  ...props
}) => {
  const [overlayIsOpen, setOverlayIsOpen] = useState(
    props.overlayIsOpen || false,
  );
  const openOverlay = useCallback(() => {
    setOverlayIsOpen(true);
  }, [setOverlayIsOpen]);
  return (
    <Context.Provider value={{ overlayIsOpen, setOverlayIsOpen, openOverlay }}>
      <Context.Consumer>{children}</Context.Consumer>
    </Context.Provider>
  );
};

export const useOverlayState = (overlayIsOpen?: boolean) => {
  const state = React.useContext(Context);

  if (overlayIsOpen !== undefined) state.overlayIsOpen = overlayIsOpen;

  return state;
};

export default CalloutsOverlayProvider;
