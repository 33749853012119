import {
  GetCtaFromMarketProps,
  GetHrefFromFleetUrlProps,
} from './volvo-id-links.types';

export const getHrefFromFleetUrl = ({
  fallbackHref,
  path,
  market,
  domain,
}: GetHrefFromFleetUrlProps) =>
  market && domain
    ? new URL(`${domain}/${market}/business/fleet${path ?? ''}`)?.href
    : fallbackHref;

export const getCtaFromMarket = ({ id, marketCTAs }: GetCtaFromMarketProps) =>
  marketCTAs?.[id];
