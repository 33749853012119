'use client';
import React, { useState } from 'react';
import { TextInput } from '@volvo-cars/react-forms';
import type cbvTradeInVspecValuationProps from 'src/content-management/editorial-components/TradeInVspecValuation/TradeInVspecValuation.props';
import { Spacing } from 'src/components/layout/Spacing';
import { validate } from './validate';

const SEK = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const displayRangedPrice = (price: number) => {
  const percentage = price > 100000 ? 0.9 : 0.8;
  const higherEnd = Math.floor(price / 1000) * 1000;
  const lowerEnd = Math.floor((higherEnd * percentage) / 1000) * 1000;
  return `${SEK.format(lowerEnd)} - ${SEK.format(higherEnd)}`;
};

export const TradeInVspecValuation = (props: cbvTradeInVspecValuationProps) => {
  const [price, setPrice] = useState(0);
  const [evalError, setEvalError] = useState(false);
  const { title } = props['content'];

  const handleSubmit = async (formData: FormData) => {
    const price = await validate(formData);

    if (!price || isNaN(price)) {
      setEvalError(true);
    } else if (price) {
      setPrice(price);
      setEvalError(false);
    }
  };

  return (
    <Spacing>
      <div className="bg-secondary py-48">
        <div className="container-md stack-24">
          <h2>{title}</h2>
          <p>
            {price
              ? 'Wow! Such price. Much value.'
              : 'Get an estimated value of your Volvo car.'}
          </p>
          <div>
            {price ? (
              <>
                <h3 className="font-24 font-medium mb-32">
                  {displayRangedPrice(price)}
                </h3>
                <div className="flex gap-16">
                  <a
                    className="button-outlined"
                    href="https://www.volvocars.com"
                  >
                    browse cards
                  </a>
                  <button className="button-filled" type="button">
                    I want to be contacted by a retailer
                  </button>
                </div>
                <button
                  onClick={() => setPrice(0)}
                  type="button"
                  className="button-text micro"
                >
                  go again
                </button>
              </>
            ) : (
              <>
                <form action={handleSubmit}>
                  <div className="flex gap-24 mb-24">
                    <TextInput
                      className="w-full"
                      label="Reg. number"
                      name="regNumber"
                      required
                    />
                    <TextInput
                      className="w-full"
                      label="Kilometers"
                      name="kilometers"
                      required
                    />
                  </div>
                  <button className="button-filled mx-auto" type="submit">
                    Estimate value
                  </button>
                </form>
                {evalError && (
                  <p className="micro text-feedback-red mt-24">
                    Oopsie! There was an error ¯\_(ツ)_/¯
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Spacing>
  );
};
