import React from 'react';
import {
  AssetSpecificationBlock,
  AssetSpecificationType,
} from '@vcc-www/asset-spec-helper';

const specification: AssetSpecificationType = {
  title: 'TilesBundle',
  assets: [
    {
      name: 'Mobile',
      variations: [
        {
          name: '(16:9) - 1, 2 or 3 assets',
          format: 'jpg, mp4 or webM',
          fileName: '<descriptive-name>_tiles-bundle_2560x1440.jpg',
          resolution: [2560, 1440],
          example: {
            maxWidth: 1280,
          },
        },
      ],
    },
    {
      name: 'Tablet',
      variations: [
        {
          name: '(16:9) - 1, 2 or 3 assets',
          format: 'jpg, mp4 or webM',
          fileName: '<descriptive-name>_tiles-bundle_2560x1440.jpg',
          resolution: [2560, 1440],
          example: {
            maxWidth: 1280,
          },
        },
      ],
    },
    {
      name: 'Desktop',
      variations: [
        {
          name: '(16:9) - 1, 2 or 3 assets',
          format: 'jpg, mp4 or webM',
          fileName: '<descriptive-name>_tiles-bundle_2560x1440.jpg',
          resolution: [2560, 1440],
          example: {
            maxWidth: 1280,
          },
        },
      ],
    },
  ],
};

const Component: React.FC<React.PropsWithChildren<unknown>> = () => (
  <AssetSpecificationBlock {...specification} />
);

export default { specification, Component };
