import { useCallback, useState } from 'react';

import { useEventListener } from './useEventListener';

type EventName = `local-storage-${string}`;
declare global {
  interface WindowEventMap {
    [key: EventName]: CustomEvent;
  }
}

const isBrowser = typeof window !== `undefined`;

const isJson = (value: string | null) => {
  try {
    if (value) JSON.parse(value);
  } catch (error: any) {
    return false;
  }
  return true;
};

const getValue = (key: string, defaultValue: any) => () => {
  if (!isBrowser) return defaultValue;
  try {
    const storedValue = window.localStorage.getItem(key);
    const parsedValue =
      storedValue && isJson(storedValue)
        ? JSON.parse(storedValue)
        : storedValue;
    return storedValue ? parsedValue : defaultValue;
  } catch (error) {
    console.log(
      'useLocalStorage: error getting localstorage value, falling back to default:',
      error,
    );
    return defaultValue;
  }
};

export const useLocalStorage = <T>(key: string, defaultValue: T) => {
  const [localStorageValue, setLocalStorageValue] = useState<T>(
    getValue(key, defaultValue),
  );

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(localStorageValue) : value;
        setLocalStorageValue(valueToStore);
        if (isBrowser) {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
          window.dispatchEvent(new Event(`local-storage-${key}`));
        }
      } catch (error) {
        console.log(
          'useLocalStorage: error setting localstorage value:',
          error,
        );
      }
    },
    [key, localStorageValue],
  );

  const handleStorageChange = useCallback(() => {
    setLocalStorageValue(getValue(key, defaultValue));
  }, [key, defaultValue]);

  // this only works between tabs
  useEventListener('storage', handleStorageChange);

  // this is a custom event
  useEventListener(`local-storage-${key}`, handleStorageChange);

  return [localStorageValue, setValue] as const;
};
