'use client';

import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useCarCarouselFeatureFlags } from '../../hooks/useCarCarouselFeatureFlags';
import { useCarPrice } from '@vcc-package/car-price';

const CarPrice = dynamic(() =>
  import('@vcc-package/car-price').then((mod) => mod.CarPrice),
);

type Props = {
  purchaseFromText?: string;
  cashPrice: string;
  modelSlug: string;
  carPriceProps?: Partial<React.ComponentProps<typeof CarPrice>>;
  hideIfNoPrice?: boolean;
};

export const CarouselItemPrice = ({
  purchaseFromText,
  cashPrice,
  modelSlug,
  carPriceProps,
  hideIfNoPrice,
}: Props) => {
  const [didLoad, setDidLoad] = useState(false);
  const { isValidCarPrice, loading } = useCarPrice({
    modelSlug,
    ...carPriceProps,
  });

  const { shouldUseCarPriceComponent } = useCarCarouselFeatureFlags();
  const purchaseFromTextWithPrice =
    cashPrice && purchaseFromText
      ? purchaseFromText.replace('{price}', cashPrice)
      : null;

  useEffect(() => {
    if (loading) {
      setDidLoad(true);
    }
  }, [loading]);

  if (hideIfNoPrice && !isValidCarPrice && !loading && didLoad) {
    throw new Error('No price found'); // Throwing this so NoCarPriceErrorBoundary can catch this and remove the item from the carousel
  }

  if (loading) {
    return null;
  }

  return shouldUseCarPriceComponent ? (
    <CarPrice modelSlug={modelSlug} {...carPriceProps} />
  ) : (
    purchaseFromTextWithPrice && <span>{purchaseFromTextWithPrice}</span>
  );
};

export default CarouselItemPrice;
