'use client';

import { cssJoin } from '@volvo-cars/css/utils';
import React from 'react';
import Carousel, { useCarousel } from '../../../index';

type Props = {
  centerAlign: boolean;
  allLabel?: string;
};

const CarouselToolbar = ({ centerAlign, allLabel }: Props) => {
  const { carouselIsScrollable, filters } = useCarousel();
  const invisibleNavigationInsteadOfHidden =
    !carouselIsScrollable && filters.length > 0;

  return (
    <div className={cssJoin('flex-col md:flex-row gap-16')}>
      {centerAlign && <div className="hidden md:block" style={{ flex: '1' }} />}
      <Carousel.Filter allLabel={allLabel ?? ''} />
      <Carousel.Navigation
        className={cssJoin(
          'self-end mr-auto md:justify-end md:mr-0',
          invisibleNavigationInsteadOfHidden && 'invisible block',
        )}
        style={{ flex: '1' }}
      />
    </div>
  );
};

export default CarouselToolbar;
