'use client';

import React from 'react';
import {
  CarInfoProps,
  useCarouselDictionaries,
} from '../../hooks/useCarouselDictionaries';

const CarInfo = ({ engineType, body }: CarInfoProps) => {
  const { carInfo } = useCarouselDictionaries({ engineType, body });
  return <>{carInfo}</>;
};

export default CarInfo;
