export function calculateTranslateXPixelValue(element: HTMLDivElement): number {
  if (!element.parentElement) {
    throw new Error('Element does not have a parent element.');
  }

  const parentWidth = element.parentElement.clientWidth;
  const elementWidth = element.clientWidth;

  // Calculate the center points
  const parentCenter = parentWidth / 2;
  const elementCenter = elementWidth / 2;

  // Calculate the translation needed to center the element within the parent
  const translateX = parentCenter - elementCenter;

  // Return the absolute value of the translation
  return Math.abs(translateX);
}
