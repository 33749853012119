'use client';

import React, { createRef, useEffect } from 'react';

import { cssJoin } from '@volvo-cars/css/utils';
import { PurposeType, TranslationKey } from '@vcc-package/leads-utils/api';
import {
  firstTruthyElementIfArray,
  getModel,
} from '@vcc-package/leads-utils/util';

import { useLeadsContext } from '../../context/leadsContext';
import { useModel } from '../../context/modelContext';
import { LeadsDefaultLayout } from '../../flexible-forms/LeadsDefaultLayout';

import { ThankYouPageCta } from './ThankYouPageCta';

type QuoteFormThankYouPageProps = {
  hideModel?: boolean;
};

export const QuoteFormThankYouPage = ({
  hideModel = false,
}: QuoteFormThankYouPageProps) => {
  const headingRef = createRef<HTMLHeadingElement>();

  const { purposeConfig, submitData, translate, vehicles, purpose } =
    useLeadsContext();
  const { savedModel } = useModel();

  const pno = firstTruthyElementIfArray(submitData?.productsOfInterest);
  const model = getModel({ pno, vehicles });
  const ctaLink = model?.ctaLink ?? purposeConfig?.features?.ctaLink;

  let headerTranslations: Record<PurposeType, TranslationKey> = {
    [PurposeType.ACCESSORY_REQUEST]: TranslationKey.AR_THANK_YOU_HEADING,
    [PurposeType.CONTACT_REQUEST]: TranslationKey.CR_THANK_YOU_HEADING,
    [PurposeType.KEEP_ME_UPDATED]: TranslationKey.KMU_THANK_YOU_HEADING,
    [PurposeType.CBV_CALLBACK]: TranslationKey.CR_CBV_THANK_YOU_HEADING,
    [PurposeType.OFFER_REQUEST]: TranslationKey.REQUEST_QUOTE_THANK_YOU,
  };

  const mainHeaderKey =
    purpose && translate(headerTranslations[purpose])
      ? headerTranslations[purpose]
      : TranslationKey.REQUEST_QUOTE_THANK_YOU;
  const mainHeader =
    (
      translate.customArgumentRender &&
      translate.customArgumentRender({
        key: mainHeaderKey,
        args: [savedModel?.presentation.title ?? '', submitData?.email ?? ''],
        renderArgument: (val) => val,
      })
    )?.join(' ') ?? '';

  const subHeaders =
    (translate.customArgumentRender &&
      translate.customArgumentRender({
        key: TranslationKey.REQUEST_QUOTE_THANK_YOU_SUB_HEADING,
        args: [savedModel?.presentation.title ?? '', submitData?.email ?? ''],
        renderArgument: (val) => val,
      })) ??
    [];
  const subHeaderString = subHeaders.join('');

  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [headingRef]);

  if (!purpose) {
    console.error('No purpose provided to QuoteFormThankYouPage');
    return null;
  }

  return (
    <LeadsDefaultLayout hideCTA hideModel={hideModel}>
      {ctaLink ? (
        <ThankYouPageCta ctaLink={ctaLink} />
      ) : (
        <hgroup
          className={cssJoin(
            'flex flex-col gap-16',
            hideModel ? 'self-center' : 'md:mt-64 md:pt-32',
          )}
        >
          <h1
            className="font-medium statement-3"
            ref={headingRef}
            style={{ scrollMarginTop: 84 }}
          >
            {mainHeader}
          </h1>
          {subHeaderString && (
            <p className="font-16 text-secondary">{subHeaderString}</p>
          )}
        </hgroup>
      )}
    </LeadsDefaultLayout>
  );
};
