import React from 'react';
import { CarouselProvider } from '../providers/CarouselProvider';
import { CarouselBleed } from '../../cars/src/types/carouselTypes';

const CarouselCore = ({
  children,
  bleed,
}: React.PropsWithChildren<{
  bleed?: CarouselBleed;
}>) => {
  return <CarouselProvider bleed={bleed}>{children}</CarouselProvider>;
};

export default CarouselCore;
