'use client';
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
/**
 * To add new icons, do the following:
 *
 *   1. Create <you-icon-name>.tsx in ./icons (See existing icons for example)
 *   2. Add the icon name in the array AVAILABLE_ICONS below (in alphabetical order)
 */
export const AVAILABLE_ICONS = [
  // .dom default icons
  'air-purifier',
  'alert',
  'aqi',
  'awd',
  'battery-charging',
  'battery',
  'bell',
  'blis',
  'breaks',
  'bubble',
  'calendar',
  'camera',
  'camera2',
  'car-electric-clock',
  'car-side',
  'checklist',
  'circular-economy',
  'city',
  'co2-cloud',
  'collective-agreemen',
  'connectivity',
  'consumer-relationship',
  'cube',
  'customer',
  'design',
  'devices',
  'download-whitepaper',
  'electric-plug',
  'electric',
  'electrical-engineering-battery',
  'envelope',
  'ethical-leadership',
  'ethical-sourcing',
  'extra-contributions',
  'electrification',
  'fan',
  'fast-growth',
  'fastest-transformer',
  'finance',
  'global-people-standard',
  'graph',
  'green-finance',
  'hand-with-plant',
  'happy-car',
  'headset',
  'high-average-premiu',
  'highway',
  'hybrid',
  'hq',
  'hud',
  'information-technology-digital',
  'insurance',
  'key',
  'knowledge-based-on-data',
  'liDAR',
  'large-all-included',
  'large-delivery-at-home',
  'large-no-down-payment',
  'led',
  'lightning',
  'lightning-filled',
  'leaf',
  'mild-hybrids',
  'mixed',
  'motherboard',
  'multiple-choice',
  'mail',
  'mandatory-deposit',
  'night',
  'no-deposit',
  'optional-deposit',
  'our-most-famous-lifesaver',
  'own-car',
  'ownership',
  'pin',
  'production',
  'pump',
  'personal-data',
  'road-mountains',
  'road',
  'report-vulnerability',
  'return-car',
  'safety',
  'savings',
  'select-car',
  'service',
  'snowflake',
  'softoffer',
  'software-engineering-electronics',
  'speed-cap',
  'steering-wheel',
  'switch-car',
  'society',
  'sun',
  'two-commitment-options',
  'tell-us',
  'towbar',
  'user',
  'volvo-cars-support',
  'warranty',
  'weighting-scale',
  'wrench',
  'work-with-us',
  'roadside-assistance',
] as const;

export type AvailableIcons = (typeof AVAILABLE_ICONS)[number];

/**
 * @deprecated Use `Icon` from react-icons instead.
 */
const LargeIcon_DEPRECATED: React.FC<{
  name: AvailableIcons;
  className?: string;
  style?: React.CSSProperties;
}> = ({ name, className, style }) => {
  const [icon, setIcon] = useState<{
    Component: React.ComponentType<{
      className?: string;
      style?: React.CSSProperties;
    }>;
  } | null>(null);

  useEffect(() => {
    try {
      if (AVAILABLE_ICONS.includes(name)) {
        // Using 'dynamic' instead of a Promise to resolve icons
        // Because NextJS doesn't allow promise in a 'use client' component
        const Icon = dynamic(() => import(`./icons/${name}`));
        if (Icon) {
          setIcon({ Component: Icon });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [name]);

  return icon ? <icon.Component className={className} style={style} /> : null;
};

export default LargeIcon_DEPRECATED;
