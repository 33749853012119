'use client';
import React from 'react';
import { FeaturedDocuments } from '@vcc-package/contextual-support-content';

export interface Props {
  /** Feature List Name */
  featureListName: string;
  /** Limit */
  limit?: number;
}

export const ContextualSupportContent: React.FC<
  React.PropsWithChildren<Props>
> = ({ featureListName, limit }) => (
  <FeaturedDocuments featureListName={featureListName} limit={limit || 6} />
);
