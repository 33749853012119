import React from 'react';
import DiscoveryCardPartContent from './discovery-card.part.content';
import DiscoveryCard from '.';
import {
  CardImageType,
  DiscoveryCardContentType,
  DiscoveryCardTemplateProps,
} from '../utils/types';

type Props = DiscoveryCardTemplateProps<CardImageType>;

export const DiscoveryCardStacked = ({
  responsiveImages,
  children,
  ...rest
}: Props) => {
  return (
    <DiscoveryCard variant="stacked" responsiveImages={responsiveImages}>
      {children ? (
        <div slot="main">{children}</div>
      ) : (
        <DiscoveryCardPartContent {...(rest as DiscoveryCardContentType)} />
      )}
    </DiscoveryCard>
  );
};
